import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"

const Dropdown = React.forwardRef(
  ({ hitbox, tag, direction, popover, scroller, responsive, timeout, className, children, ...props }, ref) => {

    // Constructors and Lifecycle
    // --------------------------

    const Tag                               = tag
    const elDropdownContent                 = useRef(null)
    const [active, setActive]               = useState(false)
    const [transitioning, setTransitioning] = useState(false)
    const [theDirection, setDirection]      = useState(direction)
    const [windowExists, setWindowExists]   = useState(false)

    useEffect(() => {
      if ( typeof window !== 'undefined' ) {
        setWindowExists(true)
      }
    }, [])

    // useEffect(() => {
    //   return () => {
    //     
    //   }
    // }, [])


    // Helpers
    // -------
    // 01. Only run this block if the dropdown isn't already active, has the
    //     `responsive` flat set, and is running on the frontend.

    let timer = null

    const show = () => {
      clearTimeout(timer)
      if ( ! active ) {
        setTransitioning(true)
      }
      setActive(true)

      if ( ! active && responsive && windowExists ) { // 01
        let winW = window.innerWidth
        let winH = window.innerHeight
        let rect = elDropdownContent.current.getBoundingClientRect()

        let valT = rect.top
        let valL = rect.left
        let valR = winW - rect.right
        let valB = winH - rect.bottom

        let outT = Math.sign(valT) === -1
        let outL = Math.sign(valL) === -1
        let outR = Math.sign(valR) === -1
        let outB = Math.sign(valB) === -1

        if ( outT || outL || outR || outB ) {
          let x = valR < valL ? 'w' : 'e'
          let y = valB < valT ? 'n' : 's'
          setDirection(`${y}${x}`)
        }
      }
    }

    const hide = () => {
      timer = setTimeout(() => {
        setTransitioning(true)
        setActive(false)
      }, timeout)
    }


    // Events
    // ------

    const onTransitionEnd = () => {
      setTransitioning(false)
    }


    // classNames
    // ----------

    const renderDropdownClassName = () => {
      let theClassName = ['hcc-dropdown']

      if ( className ) { theClassName.push(className)   }
      if ( active )    { theClassName.push('is-active') }

      return theClassName.join(' ')
    }

    const renderDropdownContentClassName = () => {
      let theClassName = ['hcc-dropdown-content', `is-${theDirection}`]

      if ( active )        { theClassName.push('is-active')        }
      if ( transitioning ) { theClassName.push('is-transitioning') }
      if ( popover )       { theClassName.push('is-popover')       }
      if ( scroller )      { theClassName.push('is-scroller')      }

      return theClassName.join(' ')
    }


    // Output
    // ------

    return (
      <Tag
        ref          = {ref}
        className    = {renderDropdownClassName()}
        onFocus      = {show}
        onBlur       = {hide}
        onMouseEnter = {show}
        onMouseLeave = {hide}
        {...props}
      >
        {hitbox}
        <div
          ref={elDropdownContent}
          className={renderDropdownContentClassName()}
          onTransitionEnd={onTransitionEnd}
        >
          {scroller ? <div className="tco-dropdown-content-scroller">{children}</div> : children}
        </div>
      </Tag>
    )
  }
)

Dropdown.defaultProps = {
  hitbox     : null,
  tag        : 'div',
  direction  : 'se',
  popover    : false,
  scroller   : false,
  responsive : false,
  timeout    : 500,
  className  : '',
  children   : null,
}

Dropdown.propTypes = {
  hitbox     : PropTypes.object,
  tag        : PropTypes.string,
  direction  : PropTypes.string,
  popover    : PropTypes.bool,
  scroller   : PropTypes.bool,
  responsive : PropTypes.bool,
  timeout    : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className  : PropTypes.string,
  children   : PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
}

export default Dropdown
