import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import * as moment from "moment"
import { find, findIndex, range, some, uniq } from "lodash"
import Button from "../components/Button"
import Container from "../components/Container"
import Dropdown from "../components/Dropdown"
import Jumbotron from "../components/Jumbotron"
import Section from "../components/Section"
import Site from "../components/Site"
import SiteLink from "../components/SiteLink"
import StickyFilters from "../components/StickyFilters"
import Svg from "../components/Svg"
import { formatLink, formatTime, getURLSearchParams } from "../helpers"
import "../css/events.scss"

const EventsPage = ({data, ...props}) => {

  // Constructors and Lifecycle
  // --------------------------
  // 01. If `?ministry=men` is set on the URL, show only events for "Men."
  // 02. Returns an array of months with events using our format to use for
  //     navigation (e.g. ['2020-01', '2020-02', '2020-03', ...]). This will
  //     even include past months, but since navigation is disabled before
  //     the current month it isn't a problem.
  // 03. Use `?month=YYYY-MM` if present, otherwise start with the first month
  //     available in the current set of events (e.g. if `?ministry=men` and
  //     the first available event is 2 months in the future, start there).
  // 04. Always start with "today" as current date.

  const theFuture                                  = [...data.allDatoCmsEvent.edges].filter((el, i, arr) => { return filterFutureEvents(arr[i].node.start) }).map(({node : event}) => event)
  const theMinistries                              = [...data.allDatoCmsMinistry.edges].map(({node : ministry}) => ministry)
  const dataViews                                  = ['calendar', 'feed']
  const uParams                                    = getURLSearchParams()
  const [theView, setView]                         = useState(dataViews[0])
  const [theMinistry, setMinistry]                 = useState(uParams.has('ministry') ? uParams.get('ministry') : null) // 01
  const [theEvents, setEvents]                     = useState(theMinistry === null ? theFuture : filterMinistryEvents(theFuture, theMinistry))
  const [theMonthsWithEvents, setMonthsWithEvents] = useState(filterMonthsWithEvents(theEvents)) // 02
  const [theMonth, setMonth]                       = useState(uParams.has('month') ? uParams.get('month') : theMonthsWithEvents[0]) // 03
  const [theCurrentDate, setCurrentDate]           = useState(moment().format(ddd)) // 04 `${theMonthsWithEvents[0]}-01` <--- needs to have this be variable if "today" is before current month (e.g. today is April 28 but the first month with an event is in may)

  const getMonthMoment = () => {
    return moment(theMonth, fff)
  }


  // Manage Ministry Filters
  // -----------------------
  // Reference: https://www.gatsbyjs.org/docs/gatsby-link/#handling-stale-client-side-pages
  // 
  // 01. The clicked element has a pathname that leads to our Events page.
  // 02. The clicked element has no query strings. This effectively means the
  //     user is trying to navigate to the Events "home" page, so we reset.
  // 03. The clicked element has a query string with a key of `ministry,` so
  //     we show the filtered results.

  const manageMinistryFilters = e => {
    if ( typeof e.target.pathname !== 'undefined' && e.target.pathname === '/events/' ) { // 01

      if ( ! e.target.search ) { // 02
        let newMonthsWithEvents = filterMonthsWithEvents(theFuture)

        setMinistry(null)
        setEvents(theFuture)
        setMonthsWithEvents(newMonthsWithEvents)
        setMonth(newMonthsWithEvents[0])
      }

      if ( !! e.target.search && e.target.search.includes('ministry') ) { // 03
        let mParams             = new URLSearchParams(e.target.search)
        let newMinistry         = mParams.get('ministry')
        let newEvents           = filterMinistryEvents(theFuture, newMinistry)
        let newMonthsWithEvents = filterMonthsWithEvents(newEvents)

        setMinistry(newMinistry)
        setEvents(newEvents)
        setMonthsWithEvents(newMonthsWithEvents)
        setMonth(newMonthsWithEvents[0])
      }

    }
  }

  // useEffect(() => {
  //   console.table(theEvents)
  //   console.log(theMonthsWithEvents)
  // })

  useEffect(() => {
    document.addEventListener('click', manageMinistryFilters)

    return () => {
      document.removeEventListener('click', manageMinistryFilters)
    }
    /* eslint-disable-next-line */
  }, [])


  // Days / Dates / Weeks
  // --------------------
  // 01. Formats dates by starting from `1` and always using `DD` format.
  // 02. Removes the ending days overlapping into this month.
  // 03. Removes the starting days overlapping into this month. `6` is used as
  //     the last day of the week, starting from `0` and counting up.
  // 04. Returns an array starting from `0` of the number of weeks in a month,
  //     which can be looped over later (e.g. [0, 1, 2, 3, 4`])

  const daysOfTheWeek = () => { return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] }
  const iStartDay     = () => { return getMonthMoment().startOf('month').day()           }
  const iEndDay       = () => { return getMonthMoment().endOf('month').day()             }

  const formatDates = (theDates, theFormat) => {
    return theDates.map(theDate => theDate < 9 ? `${theFormat}-0${theDate + 1}` : `${theFormat}-${theDate + 1}`) // 01
  }

  const prevDates = () => {
    const prevMonth   = getMonthMoment().subtract(1, 'months')
    const theDatesAll = range(0, prevMonth.daysInMonth())
    const theDatesCut = theDatesAll.slice(theDatesAll.length - iStartDay(), theDatesAll.length) // 02
    return formatDates(theDatesCut, prevMonth.format(fff))
  }

  const thisDates = () => {
    const thisMonth   = getMonthMoment()
    const theDatesAll = range(0, thisMonth.daysInMonth())
    return formatDates(theDatesAll, thisMonth.format(fff))
  }

  const nextDates = () => {
    const nextMonth   = getMonthMoment().add(1, 'months')
    const theDatesAll = range(0, nextMonth.daysInMonth())
    const theDatesCut = theDatesAll.slice(0, 6 - iEndDay()) // 03
    return formatDates(theDatesCut, nextMonth.format(fff))
  }

  const allDatesWithData = () => {
    return [
      ...prevDates().map(theDate => { return { date : theDate, context : "prev" } }),
      ...thisDates().map(theDate => { return { date : theDate, context : "now"  } }),
      ...nextDates().map(theDate => { return { date : theDate, context : "next" } })
    ]
  }

  const weeksInMonth = () => {
    return range(0, Math.floor(allDatesWithData().length / 7)) // 04
  }


  // Events
  // ------

  const currentEventsWithData = (events, timeframe = 'month') => {
    const theEvents = []
    const theDates  = allDatesWithData()

    events.map(theEvent => {
      const mStart           = moment(theEvent.start, ttt)
      const timeframeIsAll   = timeframe === 'all'
      const timeframeIsMonth = timeframe === 'month' && getMonthMoment().format(fff) === mStart.format(fff)
      const timeframeIsDay   = timeframe === 'day' && mStart.format(ddd) === theCurrentDate

      if ( timeframeIsAll || timeframeIsMonth || timeframeIsDay ) {
        const fStart           = mStart.format(ddd)
        const iStart           = findIndex(theDates, theDate => theDate.date === fStart)
        const startColumnCoord = getColumnCoord(iStart)
        const startRowCoord    = getRowStartCoord(iStart)
        const mEnd             = moment(theEvent.end, ttt)
        const fEnd             = mEnd.format(ddd)
        const iEnd             = findIndex(theDates, theDate => theDate.date === fEnd)
        const duration         = Math.ceil(moment.duration(mEnd.diff(mStart)).asDays())
        const durationRange    = range(0, duration)
        const dateSlug         = `${theEvent.slug}-${fStart}`

        theEvents.push({
          id               : theEvent.id,
          title            : theEvent.title,
          slug             : theEvent.slug,
          dateSlug         : dateSlug,
          link             : formatLink(dateSlug, 'e'),
          location         : theEvent.location,
          ministries       : theEvent.ministries,
          hasChildcare     : theEvent.hasChildcare,
          registrationLink : theEvent.registrationLink,
          costMin          : theEvent.costMin,
          costMax          : theEvent.costMax,
          startRaw         : theEvent.start,
          startFormat      : fStart,
          startIndex       : iStart,
          startColumnCoord : startColumnCoord,
          startRowCoord    : startRowCoord,
          endRaw           : theEvent.end,
          endFormat        : fEnd,
          endIndex         : iEnd,
          duration         : durationRange,
        })
      }

      return true
    })

    return theEvents
  }

  const hasCurrentEvents = () => {
    return theEvents.length > 0
  }


  // Navigation
  // ----------

  const stopPrev = () => { return ! hasCurrentEvents() ? true : moment([...theEvents].shift().start, ttt).format(fff) === getMonthMoment().format(fff) }
  const stopNext = () => { return ! hasCurrentEvents() ? true : moment([...theEvents].pop().start, ttt).format(fff) === getMonthMoment().format(fff)   }
  const iNow     = () => { return theMonthsWithEvents.indexOf(getMonthMoment().format(fff))                                                            }
  const goPrev   = () => { setMonth(theMonthsWithEvents[iNow() - 1])                                                                                   }
  const goNext   = () => { setMonth(theMonthsWithEvents[iNow() + 1])                                                                                   }

  const hrefPrev = () => {
    if ( stopPrev() ) {
      return null
    } else if ( uParams.has('month') ) {
      uParams.set('month', theMonthsWithEvents[iNow() - 1])
      return `${formatLink('', 'e')}/?${uParams.toString()}`
    } else {
      return null
    }
  }

  const hrefNext = () => {
    if ( stopNext() ) {
      return null
    } else if ( uParams.has('month') ) {
      uParams.set('month', theMonthsWithEvents[iNow() + 1])
      return `${formatLink('', 'e')}/?${uParams.toString()}`
    } else {
      return null
    }
  }


  // Output
  // ------

  // const currentEventsWithDataAll   = currentEventsWithData(theEvents, 'all')
  const currentEventsWithDataMonth = currentEventsWithData(theEvents, 'month')
  const currentEventsWithDataDay   = currentEventsWithData(theEvents, 'day')

  return (
    <Site
      title     = "Events"
      className = "hcc-events is-index"
      data-datocms-noindex
    >
      <Section tag="header" className="hcc-events-intro">
        <Container>
          <Jumbotron content={
            theMinistry ? <>{hasCurrentEvents() ? 'Here\'s everything for' : 'No events found for'} <br/>the <span style={{ color : `var(--c-${theMinistry})` }}>{find(theMinistries, ['slug', theMinistry]).reference.toLowerCase()}</span> ministry</>
                        : <>Keep up with what&apos;s going on at&nbsp;Hillside</>
          }/>
          {! hasCurrentEvents() &&
            <Button prefix={<i><Svg type="ctrl-left"/></i>} label="Back to Events" href="/events/" style={{ '--c-base-accent' : `var(--c-${theMinistry})` }}/>
          }
        </Container>
      </Section>
      {hasCurrentEvents() &&
        <StickyFilters>
          <Container>
            <div className="is-prev-next">
              <Button label={<Svg type="ctrl-left"/>} href={hrefPrev()} className="is-ui" onClick={goPrev} disabled={stopPrev()}/>
              <Button label={<Svg type="ctrl-right"/>} href={hrefNext()} className="is-ui" onClick={goNext} disabled={stopNext()}/>
            </div>
            <strong>
              <span className="is-abbr">{getMonthMoment().format('MMM')}{getMonthMoment().format('MMM') !== 'May' && '.'}</span>
              <span className="is-full">{getMonthMoment().format('MMMM')}</span>
            </strong>
            <div className="is-view-selection">
              <Button label={<Svg type="grid"/>} className={theView === dataViews[0] ? 'is-ui is-active' : 'is-ui'} onClick={() => setView(dataViews[0])} disabled={! hasCurrentEvents()}/>
              <Button label={<Svg type="feed-bold"/>} className={theView === dataViews[1] ? 'is-ui is-active' : 'is-ui'} onClick={() => setView(dataViews[1])} disabled={! hasCurrentEvents()}/>
            </div>
          </Container>
        </StickyFilters>
      }
      {(theView === 'calendar' && hasCurrentEvents()) &&
        <Section className="hcc-events-calendar">
          <Container size="xl">
            <div className="hcc-calendar is-head">
              {daysOfTheWeek().map(theDay => <div key={theDay} className="hcc-calendar-day"><span>{theDay.charAt(0)}<span>{theDay.charAt(1)}{theDay.charAt(2)}</span></span></div>)}
            </div>
            <div className="hcc-calendar is-body">
              <CalendarDates
                dates          = {allDatesWithData()}
                events         = {currentEventsWithDataMonth}
                theCurrentDate = {theCurrentDate}
                setCurrentDate = {setCurrentDate}
              />
              <CalendarEvents
                dates          = {allDatesWithData()}
                events         = {currentEventsWithDataMonth}
                getMonthMoment = {getMonthMoment}
                iStartDay      = {iStartDay}
                weeksInMonth   = {weeksInMonth}
              />
            </div>
          </Container>
          <ul className="hcc-events-feed-list">
            {currentEventsWithDataDay.length > 0 && currentEventsWithDataDay.map(event => {
              return <FeedItem key={event.id} data={event}/>
            })}
            {currentEventsWithDataDay.length === 0 &&
              <li className="hcc-events-feed-empty"><span>No events for <strong>{moment(theCurrentDate, ddd).format('MMMM Do')}</strong></span></li>
            }
          </ul>
        </Section>
      }
      {(theView === 'feed' && hasCurrentEvents()) &&
        <Section className="hcc-events-feed">
          <ul className="hcc-events-feed-list">
            {currentEventsWithDataMonth.map(event => {
              return <FeedItem key={event.id} data={event}/>
            })}
          </ul>
        </Section>
      }
      {/*
      <Section className="hcc-events-feed is-map">
        <ul className="hcc-events-feed-list">
          {currentEventsWithDataAll.map(event => {
            return <FeedItem key={event.id} data={event}/>
          })}
        </ul>
      </Section>
      */}
    </Site>
  )

}

export default EventsPage


// Calendar Dates
// --------------

const CalendarDates = ({ dates, events, theCurrentDate, setCurrentDate }) => {
  let theDates = []

  dates.map((theDate, i) => {
    let dateClassName          = ['hcc-calendar-date', `is-${theDate.context}`]
    let dateSelectionClassName = ['hcc-calendar-date-selection', 'has-no-outline']
    let gridColumn             = `${getColumnCoord(i)} / span 1`
    let gridRow                = `${getRowStartCoord(i)} / span 1`

    if ( getColumnCoord(i) === 1 || getColumnCoord(i) === 7 ) {
      dateClassName.push('is-weekend')
    }

    if ( theDate.date === moment().format(ddd) ) {
      dateClassName.push('is-today')
      dateSelectionClassName.push('is-today')
    }

    if ( findIndex(events, (el) => { return theDate.date === el.startFormat }) !== -1 ) {
      dateSelectionClassName.push('has-events')
    }

    if ( theCurrentDate === theDate.date ) {
      dateSelectionClassName.push('is-active')
    }

    theDates.push(
      <div key={`date-${theDate.date}`} className={dateClassName.join(' ')} style={{ gridColumn, gridRow }}>
        <b>{moment(theDate.date, ddd).format('D')}</b>
        {theDate.context === 'now' &&
          <Button
            label     = {<span className="is-date">{moment(theDate.date, ddd).format('D')}</span>}
            className = {dateSelectionClassName.join(' ')}
            style     = {{ gridColumn, gridRow }}
            onClick   = {() => setCurrentDate(theDate.date)}
          />
        }
      </div>
    )

    return true
  })

  return theDates
}


// Calendar Events
// ---------------

const CalendarEvents = ({ dates, events, getMonthMoment, iStartDay, weeksInMonth }) => {
  let theWeeks  = []

  weeksInMonth().map(theWeek => {
    let weekRowCoord = getRowStartCoord(theWeek * 7)

    theWeeks.push(
      <div key={`w-${theWeek}`} className="hcc-calendar-week" style={{ gridColumn : '1 / -1', gridRow : `${weekRowCoord} / span 1` }}>
        <div className="hcc-calendar-week-grid">
          {events.map(theEvent => {
            if ( theEvent.startRowCoord === weekRowCoord ) {
              return (
                <div
                  key       = {theEvent.id}
                  className = "hcc-calendar-event"
                  style     = {{ '--c-base-accent' : `var(--c-${theEvent.ministries[0].slug})`, gridColumn : `${theEvent.startColumnCoord} / span 1`, gridRow : 'auto / span 1' }}
                >
                  <Dropdown responsive={true} timeout={300} hitbox={
                    <>
                      {/*
                      <a href={theEvent.registrationLink} target="_blank" rel="noopener noreferrer" className="hcc-calendar-event-link">
                        <span>{theEvent.title}</span>
                      </a>
                      */}
                      <SiteLink href={formatLink(theEvent.dateSlug, 'e')} className="hcc-calendar-event-link">
                        <span>{theEvent.title}</span>
                      </SiteLink>
                    </>
                  }>
                    <div className="is-info">
                      <h6>
                        {/*
                        <a href={theEvent.registrationLink} target="_blank" rel="noopener noreferrer">{theEvent.title}</a>
                        */}
                        <SiteLink href={formatLink(theEvent.dateSlug, 'e')}>{theEvent.title}</SiteLink>
                      </h6>
                      <ul>
                        <li className="is-time">
                          <i><Svg type="at"/></i>
                          <span>{formatTimeMarkup(theEvent.startRaw, theEvent.endRaw)}</span>
                        </li>
                        {(theEvent.costMin !== null || theEvent.costMax !== null) &&
                          <li className="is-cost">
                            <i><Svg type="money"/></i>
                            <span>{formatPriceMarkup(theEvent.costMin, theEvent.costMax)}</span>
                          </li>
                        }
                        <li className="is-location">
                          <i><Svg type="location"/></i>
                          <span>{theEvent.location.title}</span>
                        </li>
                        <li className="is-ministries">
                          <i><Svg type="ministries"/></i>
                          <span>{formatMinistries(theEvent.ministries)}</span>
                        </li>
                        {theEvent.hasChildcare &&
                          <li className="is-childcare">
                            <i><Svg type="rsvp-circle"/></i>
                            <span>Childcare</span>
                          </li>
                        }
                      </ul>
                    </div>
                  </Dropdown>
                </div>
              )
            }
            return true
          })}
        </div>
      </div>
    )

    return true
  })

  return theWeeks
}


// Feed Item
// ---------

const FeedItem = ({ data }) => {
  return (
    <li className="hcc-events-feed-item" style={{ '--c-base-accent' : `var(--c-${data.ministries[0].slug})` }}>
      {/*
      <a href={data.registrationLink} target="_blank" rel="noopener noreferrer">{data.title}</a>
      */}
      <SiteLink href={formatLink(data.dateSlug, 'e')}>{data.title}</SiteLink>
      <article>
        <div className="is-timeline">
          {formatTime(data.startRaw, 'eventTimeline')}
          {data.ministries.length > 1 &&
            <span className="hcc-events-ministry-badges is-in-feed">
              {data.ministries.map((ministry, i, arr) =>
                <i key={`${data.id}-${ministry.slug}`} style={{ '--c-base-accent' : `var(--c-${ministry.slug})`, zIndex : arr.length - i }}><span className="is-hidden">{ministry.name}</span></i>
              )}
            </span>
          }
        </div>
        <div className="is-content">
          <div className="is-meta">
            <span><Svg type="at"/> {formatTimeMarkup(data.startRaw, data.endRaw)}</span>
          </div>
          <h2 className="is-title"><b><i>{data.title}</i></b></h2>
          <div className="is-meta">
            <span><Svg type="location"/> {data.location.title}</span>
            {/*<span><Svg type="ministries"/> {formatMinistries(data.ministries)}</span>*/}
            {(data.costMin !== null || data.costMax !== null) &&
              <span><Svg type="money-circle"/> {formatPriceMarkup(data.costMin, data.costMax)}</span>
            }
            {data.hasChildcare &&
              <span><Svg type="rsvp-circle"/> Childcare</span>
            }
          </div>
        </div>
      </article>
    </li>
  )
}


// Formats
// -------

const ttt = 'YYYY-MM-DDTHH:mm:ss-Z'
const fff = 'YYYY-MM'
const ddd = 'YYYY-MM-DD'


// Filters
// -------

const filterFutureEvents = (start) => {
  return moment(start, ttt).format('x') > Date.now()
}

const filterMonthsWithEvents = (theEvents) => {
  return uniq(theEvents.map(el => { return moment(el.start, ttt).format(fff) }))
}

const filterMinistryEvents = (theEvents, theMinistry) => {
  if ( theMinistry === null ) {
    return theEvents
  }
  return theEvents.filter((el, i, arr) => {
    return some(arr[i].ministries, ['slug', theMinistry])
  })
}


// Coordinates
// -----------

const getWeekIndexFromDateIndex = (iDate) => {
  let iWeek
  switch (true) {
    case iDate >= 0  && iDate <= 6  : iWeek = 0; break
    case iDate >= 7  && iDate <= 13 : iWeek = 1; break
    case iDate >= 14 && iDate <= 20 : iWeek = 2; break
    case iDate >= 21 && iDate <= 27 : iWeek = 3; break
    case iDate >= 28 && iDate <= 34 : iWeek = 4; break
    case iDate >= 35 && iDate <= 41 : iWeek = 5; break
    default                         : iWeek = 0;
  }
  return iWeek
}

const getColumnCoord   = (i) => { return ((i % 7) + 1)                    }
const getRowStartCoord = (i) => { return getWeekIndexFromDateIndex(i) + 1 }


// Formatting
// ----------

const formatPriceMarkup = (min, max) => {
  return (
    <>
      {(min === null && max === null) && <span>Free</span> }
      {(min !== null && max === null) && <span>{`${min}`}</span> }
      {(min === null && max !== null) && <span>{`${max}`}</span> }
      {(min !== null && max !== null) && <span><span>{`${min}`}</span>&nbsp;/&nbsp;<span>{`${max}`}</span></span>}
    </>
  )
}

const formatTimeMarkup = (start, end) => {
  const eventIsOneDay = moment(start, ttt).format(ddd) === moment(end, ttt).format(ddd)

  return (
    <>
      {formatTime(start, 'timeLong')}
      {
        eventIsOneDay ? <>&nbsp;-&nbsp;{formatTime(end, 'timeLong')}</>
                      : <>&nbsp;&rarr;&nbsp;{formatTime(end, 'eventEndLong')}</>
      }
    </>
  )
}

const formatMinistries = (ministries) => {
  return (
    <>
      {ministries.map((el, i, arr) => <span key={`${el.id}-${el.name}`}>{el.name}{i !== arr.length - 1 && ", "}</span>)}
    </>
  )
}


// GraphQL
// -------

export const query = graphql`
  query EventQueryIndex {
    allDatoCmsEvent(sort: {fields: start, order: ASC}) {
      edges {
        node {
          ...EventFragment
        }
      }
    }
    allDatoCmsMinistry {
      edges {
        node {
          ...MinistryFragment
        }
      }
    }
  }
`


// <li key={event.id}>
//   <a href={event.registrationLink}>
//     <h4>{event.title}</h4>
//     <p>{event.start} | {event.end}</p>
//     <p>LAT: {event.location.coordinates.latitude} | LONG: {event.location.coordinates.longitude}</p>
//     <p>MINISTRIES: {event.ministries.map(el => <span key={el.name}> {el.name} </span>)}</p>
//   </a>
// </li>
